* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.animation-background {
  width: 100wh;
  height: auto;
  background: linear-gradient(-45deg, #121214, #222226, #19191e, #1e1e23);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;

  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.video-background {
      padding: 0;
      height: 100vh;
    }
  }

  &.overlay {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}
