.card-inner {
  padding: 0;
  position: absolute;
  overflow: hidden;
  // left: 480px;
  left: 560px;
  right: 0;
  top: 15px;
  bottom: 15px;
  width: auto;
  height: auto;
  background: #31313a;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -khtml-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -moz-transition: visibility 1s linear, opacity 0s linear;
  -webkit-transition: visibility 1s linear, opacity 0s linear;
  -o-transition: visibility 1s linear, opacity 0s linear;
  transition: visibility 1s linear, opacity 0s linear;
  transform-origin: center left;

  @media (max-width: 1199px) {
    position: relative;
    margin: 15px 0;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    border-radius: 4px;
  }

  &.animated {
    opacity: 1;
    visibility: visible;
    z-index: 9;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    height: 30px;
    background: #31313a;
    z-index: 10;
  }

  .card-wrap {
    padding: 30px 30px 0 30px;
    position: relative;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
