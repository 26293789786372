@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FontAwesome";
  src: local("FontAwesome"),
    url("./assets/fonts/FontAwesome/fa-regular-400.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "FontAwesome";
  src: local("FontAwesome"),
    url("./assets/fonts/FontAwesome/fa-brands-400.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "FontAwesome";
  src: local("FontAwesome"),
    url("./assets/fonts/FontAwesome/fa-solid-900.ttf") format("truetype");
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5); /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

html {
  margin-right: 0 !important;
  background: linear-gradient(-45deg, #17171b, #31313a, #28282f, #31313a);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

body {
  margin: 0;
  padding: 0;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.55);
  background: #26262d;
  letter-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
  line-height: 1.8em;
  margin: 0 0 25px 0;
}

.about p {
  margin-bottom: 0;
}

li {
  margin-bottom: 0px;
}
